.qualification__container {
    max-width: 350px;
}

.qualification__active {
    color: var(--title-color-dark);
}

.qualification__sections {
    display: grid;
    justify-content: center;
}

.qualification__content {
    display: block;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
    margin: 0;
    padding: 0;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: 8px;
}

.qualification__calender {
    font-size: var(--small-font-size);
}

.qualification__rounder {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: var(--text-color);
    border-radius: 50%;
    justify-content: center;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(7px, -7px);
}

@media screen and (max-width: 992px){
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
  }

  @media screen and (max-width: 768px){
    .qualification__container {
        grid-template-columns: initial;
    }
  }
  @media screen and (max-width: 576px){
    .qualification__container {
        grid-template-columns: initial;
    }
    .qualification__data {
        gap: 0.5rem;
    }
  }
  @media screen and (max-width: 350px){

  }
  .qualification.section {
    opacity: 0;
    transform: translateY(20px); /* Initial position for animation */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Transition for fade and slide */
  }
  
  .qualification.section.animate {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move to original position */
  }
  