.home__container {
    row-gap: 7rem;
}

.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover {
    color: var(--title-color-dark);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__img {
    background: url(../assets//home.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-self: center;
    order: 1;
    width: 350px;
    height: 350px;
    z-index: 10;
    position: relative;
    border: 2px solid rgb(166, 38, 221);
    vertical-align: middle;
}

.home__img-wrapper {
    justify-self: center;
    order: 1;
    margin-inline: auto 2rem;
    position: relative;
}

.home__img-wrapper::before,
.home__img-wrapper ::after {
    content: '';
    position: absolute;
    inset: 0;
}

.home__img-wrapper::before {
    background-color: rgba(139, 20, 175, 0.101);
    animation: animate__before 8s ease-in-out infinite;
    border: 2px solid rgb(166, 38, 221);
}

.home__img-wrapper::after,
.home__img {
    background-color: rgb(44, 44, 44);
    animation: animate__after 8s ease-in-out infinite;
}

.home__scroll {
    margin-left: auto;
    margin-right: auto;

}

.wheel {
    animation: scroll 2s ease infinite;
}

.home__scroll-name {
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
}

.slidingTextAnimation {
    position: fixed;
    font-size: 50vh;
    top: 200px;
    white-space: nowrap;
    color: rgb(241, 241, 241);
    overflow: hidden;
    font-weight: bold;
    margin: auto;
    padding: auto;
}


@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(4rem);
    }
}

@keyframes animate__before {
    0% {
        border-radius: 62% 38% 62% 38% / 61% 35% 65% 39% ;
    }

    50% {
        border-radius: 37% 63% 39% 61% / 37% 61% 39% 63% ;
    }

    100% {
        border-radius: 62% 38% 62% 38% / 61% 35% 65% 39% ;
    }
}

@keyframes animate__after {
    0% {
        border-radius: 37% 63% 39% 61% / 37% 61% 39% 63% ;
    }

    50% {
        border-radius: 62% 38% 62% 38% / 61% 35% 65% 39% ;
    }

    100% {
        border-radius: 37% 63% 39% 61% / 37% 61% 39% 63% ;
    }
}



@media screen and (max-width: 992px){
    .home__content {
        grid-template-columns: 100px repeat(2. 1fr);
        column-gap: 1.25rem;
    }

    .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-25);
    }

    .home__img {
        width: 250px;
        height: 250px;
    }

    .home__scroll {
        margin-left: 7.5rem;
    }
  }

  @media screen and (max-width: 576px){

    .home__content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .home__img {
        order: initial;
        justify-self: initial;
    }

    .home__img-wrapper {
        order: initial;
        justify-self: initial;
    }

    .home__data {
        grid-column: 1/3;
    }

    .home__img {
        width: 200px;
        height: 200px;
    }

    .home__img-wrapper {
        width: 200px;
        height: 200px;
        margin: auto;
    }

    .home__scroll {
        display: none;
    }
    
  }
  @media screen and (max-width: 350px){
    
    .home__img {
        width: 180px;
        height: 180px;
    }
    .home__img-wrapper {
        width: 180px;
        height: 180px;
    }
  }