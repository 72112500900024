.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  


}
body {
  overflow-x: hidden;
}

/* slider */
.portfolio .swiper{
    overflow: hidden!important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
 
  
}

.portfolio-slider .swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 23rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}

